.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.sub-title {
  border-bottom: 1px solid #adadad;
  font-size: 20px;
  margin-bottom: 8px;
}

.image-block {
  padding-left: 2px;
  padding-top: 2px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.video-close-buton {
  font-size: 24px;
  margin-top: -20px;
  position: absolute;
  width: 32px;
}

@media screen and (max-width: 600px) {
  .image-block {
    padding: 1px;
    background: white;
    box-sizing: border-box;
    resize: horizontal;
    overflow: auto;
    max-width: 100%;
    height: calc(33vw);
  }

  .video-close-buton {
    right: 0px;
  }
}

.simple-button {
  font-size: 14px;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  padding: 4px 30px;
}

.simple-button:disabled {
  position: relative;
  color: transparent;
}

.simple-input {
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  padding: 6px 12px;
}

.simple-button:disabled::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border: 2px solid #ccc;
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.board-table {
}

.board-table thead tr {
  border-bottom: 1px solid #e1e1e1;
}

.board-table thead tr th {
  font-size: 13px;
  font-weight: 400;
  color: #313131;
  white-space: nowrap;
}

.board-table tbody tr {
  border-bottom: 1px solid #e1e1e1;
}

.board-table tbody tr td {
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  font-weight: 400;
  padding-right: 8px;
  padding-left: 8px;
  white-space: nowrap;
}

.board-table thead tr th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #fff;
}

.board-table tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}


.data-table {

}
.data-table thead tr {
    border-bottom: 1px solid #e1e1e1;
}

.data-table thead tr th {
    text-align: left;
    padding-right: 20px;
    font-size: 13px;
    white-space: nowrap;
}

.data-table tbody tr {
    border-bottom: 1px solid #e1e1e1;
}
.data-table tbody tr:hover {
    opacity: 0.8;
    background-color: #e1e1e1;
}

.data-table tbody tr td {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
    padding-right: 20px;
    font-size: 13px;
    white-space: nowrap;
}

.input-dialog {
  border: 1px solid #e1e1e1;
  max-width: 720px;
  top: 80px;
  left: 0px;
  position: absolute;
  margin: auto;
  right: 0;
  z-index: 10;
  max-height: 80%;
  overflow-y: auto;
}

.pc-menu {
  display: block;
}

.sp-menu {
  display: none;
}

@media screen and (max-width: 600px) {
  .pc-menu {
    display: none;
  }

  .sp-menu {
    display: block;

  }
}

.hover-button {

}

.hover-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
